// src/components/ItemsList.js
import React from 'react';
import ItemCard from './ItemCard';
import './ItemsList.css'; // Import the CSS file for this component

const ItemsList = ({ items }) => {
  if (items.length === 0) {
    return <p>No items found.</p>; // Message when no items are found
  }

  return (
    <div className="items-list">
      <h2 className="featured-header">Market</h2> {/* Header should be centered */}
      <div className="items-container"> {/* New container for item cards */}
        {items.map(item => (
          <ItemCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default ItemsList;
