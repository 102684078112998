import React, { useState } from 'react';
import Homebar from './Homebar'; // Assuming Homebar is a component
import './WalletPage.css'; // Import CSS
import CheckoutForm from './CheckoutForm'; // Import CheckoutForm
import TransactionList from './TransactionList';
import { Button, Modal, Box } from '@mui/material';
import { width } from '@mui/system';
import { AlignLeft } from 'lucide-react';

function WalletPage() {
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0); // State to store balance

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleBalanceUpdate = (newBalance) => {
    // console.log("Balance updated to:", newBalance);
    setBalance(newBalance);
  };

  return (
    <div className="wallet-page">
      <Homebar onBalanceUpdate={handleBalanceUpdate} />
      <div className="content-container">
        <div className="main-content">
          <h1>Wallet</h1>
          <h3>Balance: ${(balance/100).toFixed(2)}</h3>

          {/* Green Deposit Button */}
          <Button 
            variant="contained" 
            color="success" 
            onClick={handleOpen} 
            style={{ margin: '20px 0' }}
          >
            Deposit
          </Button>

          {/* Modal for Checkout Form */}
          <Modal open={open} onClose={handleClose}>
            <Box 
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                minWidth: '100px',
                width: '80%',
                maxWidth: '400px',
                minHeight: '150px',
                maxHeight: '90vh', // Prevent overflow
                height: 'auto',
                overflow: 'auto'
              }}
            >
              <CheckoutForm />
            </Box>
          </Modal>

          <TransactionList className="wallet-transaction-list"/>

        </div>
      </div>
    </div>
  );
}

export default WalletPage;
