import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import FilterMenu from './FilterMenu';
import Homebar from './Homebar';
import './InventoryPage.css';
import { Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';

const InventoryPage = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectItem = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const existingItemIndex = prevSelectedItems.findIndex((i) => i.mhash === item.mhash);
      
      if (existingItemIndex !== -1) {
        // If item exists, remove it
        return prevSelectedItems.filter((i) => i.mhash !== item.mhash);
      } else {
        // If item doesn't exist, add it with default or preserved state
        const existingItem = prevSelectedItems.find((i) => i.mhash === item.mhash);
        const withdrawQuantity = existingItem ? existingItem.withdrawQuantity : 1;
        return [...prevSelectedItems, { ...item, withdrawQuantity }];
      }
    });
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let { data, error } = await supabase.rpc('get_inventory', {});
        
        if (error) {
          console.log("Error trying to fetch inventory", error);
        } else {
          console.log("Fetched inventory:", data);
          setItems(data);
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  return (
    <div className="inventory-page">
      <Homebar />
      <div className="content-container" style={{ display: 'flex', width: '85%', height: '100%' }}>
        <FilterMenu />
        <div className="main-content" style={{ width: '100%', height: '100%' }}>
          {/* Banner Section */}
          <Box sx={{ width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#303030' }}>
            <Typography variant="h4" sx={{ color: '#ffffff' }}>
              Your Inventory
            </Typography>
          </Box>

          {/* Inventory Items Table */}
          <Box sx={{ padding: 2 }}>
          <TableContainer sx={{ backgroundColor: '#303030' }}>
            <Table sx={{ width: '100%', borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: 2 }}></TableCell>
                  <TableCell sx={{ color: '#ffffff', padding: 2 }}>Item Name</TableCell>
                  <TableCell sx={{ color: '#ffffff', padding: 2 }}>Quantity Owned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Checkbox value={item.mhash} onChange={() => handleSelectItem(item)} />
                    </TableCell>
                    <TableCell sx={{ color: '#ffffff', padding: 2 }}>{item.mhash}</TableCell>
                    <TableCell sx={{ color: '#ffffff', padding: 2 }}>{item.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          </Box>
        </div>
      </div>
      <div className="information-panel">
        <h3>Information</h3>
        {selectedItems.length === 0 ? (
          <p>Select items to send to your Steam account</p>
        ) : (
          <div>
            <h4> Withdraw Items: </h4>
            {selectedItems.map((item, index) => (
            <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <label style={{ flex: 1 }}>
                {item.mhash}
                <input
                  type="number"
                  min="1"
                  max={item.quantity}
                  defaultValue="1"
                  style={{ marginLeft: '10px', width: '50px' }}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10);
                    setSelectedItems((prevSelectedItems) =>
                      prevSelectedItems.map((i) =>
                        i.mhash === item.mhash ? { ...i, withdrawQuantity: newQuantity } : i
                      )
                    );
                  }}
                /> / {item.quantity}
              </label>
              <button
                onClick={() => {
                  setSelectedItems((prevSelectedItems) =>
                    prevSelectedItems.filter((i) => i.mhash !== item.mhash)
                  );
                  document.querySelector(`input[type="checkbox"][value="${item.mhash}"]`).checked = false;
                }}
                className="delete-button"
              >
                🗑️
              </button>
            </div>
            ))}
            <button
              onClick={async () => {
                const commodityItems = selectedItems.map(item => item.mhash);
                const commodityQuantities = selectedItems.map(item => item.withdrawQuantity || 1);

                try {
                  const { data, error } = await supabase.rpc('create_withdrawal', {
                    p_commodity_item: commodityItems,
                    p_commodity_quantity: commodityQuantities,
                  });

                  if (error) {
                    console.error('Error creating withdrawal:', error);
                  } else {
                    alert(`Successfully withdrew ${commodityQuantities.reduce((acc, qty) => acc + qty, 0)} items`);
                    setSelectedItems([]); // Clear the selection
                    setItems((prevItems) =>
                      prevItems.map((i) =>
                        selectedItems.some((selectedItem) => selectedItem.mhash === i.mhash)
                          ? { ...i, quantity: i.quantity - selectedItems.find((selectedItem) => selectedItem.mhash === i.mhash).withdrawQuantity }
                          : i
                      )
                    );
                    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                      checkbox.checked = false;
                    });
                  }
                } catch (error) {
                  console.error('Error creating withdrawal:', error);
                }
              }}
              className="withdraw-button"
            >
              Withdraw {selectedItems.reduce((acc, item) => acc + (item.withdrawQuantity || 1), 0)} items
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryPage;
