import React from 'react';
import OrderBook from './OrderBook';

const ItemOrderBook = ({ orderBook }) => (
  <div className="right-column">
    <OrderBook orderBook={orderBook} />
  </div>
);

export default ItemOrderBook;
