import React from 'react';
import { Container, Typography, Paper, Box, Button } from '@mui/material';
import Homebar from './Homebar';
import Banner from '../components/Banner';
import { width } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import FAQSection from './FAQSection';

const HomePage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="homepage">
      <Homebar />
      <Banner />
      <div className="main-content" style={{ backgroundColor: "#545454", height:'100vh'}}>
        <FAQSection />
      </div>
    </div>
  );
};

export default HomePage;
