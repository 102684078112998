import React from 'react';
import { Facebook, Instagram, Twitter, Youtube, Mail, ExternalLink } from 'lucide-react';

const Footer = () => {
  return (
    <div style = {{height: '100%', alignItems: 'center', color: '#ffffff'}}>
      Footer
    </div>
  );
  // return (
  //   <footer className="w-full bg-gray-900 text-gray-300 py-8">
  //     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
  //       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
  //         {/* Company Info */}
  //         <div className="col-span-1 lg:col-span-2">
  //           <h3 className="text-lg font-semibold text-white mb-4">Case Exchange</h3>
  //           <p className="text-sm text-gray-400 mb-6">
  //             Your premier destination for unique and high-quality cases. We're committed to providing exceptional products and customer service.
  //           </p>
  //         </div>

  //         {/* Company Links */}
  //         <div>
  //           <h4 className="text-base font-medium text-white mb-4">Company</h4>
  //           <ul className="space-y-2">
  //             <li>
  //               <a href="/about" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
  //                 About Us
  //               </a>
  //             </li>
  //             <li>
  //               <a href="/faq" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
  //                 FAQ
  //               </a>
  //             </li>
  //             <li>
  //               <a href="/support" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
  //                 Support
  //               </a>
  //             </li>
  //           </ul>
  //         </div>

  //         {/* Legal Links */}
  //         <div>
  //           <h4 className="text-base font-medium text-white mb-4">Legal</h4>
  //           <ul className="space-y-2">
  //             <li>
  //               <a href="/privacy" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
  //                 Privacy Policy
  //               </a>
  //             </li>
  //             <li>
  //               <a href="/tos" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
  //                 Terms of Service
  //               </a>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>

  //       {/* Social and Contact */}
  //       <div className="mt-8 pt-8 border-t border-gray-800">
  //         <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
  //           <div className="flex space-x-4">
  //             <a href="https://facebook.com" className="text-gray-400 hover:text-white transition-colors duration-200">
  //               <Facebook className="w-5 h-5" />
  //             </a>
  //             <a href="https://instagram.com" className="text-gray-400 hover:text-white transition-colors duration-200">
  //               <Instagram className="w-5 h-5" />
  //             </a>
  //             <a href="https://twitter.com" className="text-gray-400 hover:text-white transition-colors duration-200">
  //               <Twitter className="w-5 h-5" />
  //             </a>
  //             <a href="https://youtube.com" className="text-gray-400 hover:text-white transition-colors duration-200">
  //               <Youtube className="w-5 h-5" />
  //             </a>
  //           </div>
            
  //           <a 
  //             href="mailto:info@case-exchange.com" 
  //             className="flex items-center text-gray-400 hover:text-white transition-colors duration-200 group"
  //           >
  //             <Mail className="w-4 h-4 mr-2" />
  //             <span>info@case-exchange.com</span>
  //             <ExternalLink className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
  //           </a>
  //         </div>
  //       </div>

  //       {/* Copyright */}
  //       <div className="mt-8 text-center text-sm text-gray-400">
  //         © {new Date().getFullYear()} Case Exchange. All rights reserved.
  //       </div>
  //     </div>
  //   </footer>
  // );
};

export default Footer;