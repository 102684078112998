import React from 'react';
import { 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

const DepthBar = styled(Box)(({ theme, type }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  zIndex: 0,
  opacity: 0.1,
  backgroundColor: type === 'bid' ? theme.palette.success.main : theme.palette.error.main,
}));

const MAX_ROWS = 5;
const PLACEHOLDER_ROW = { price: null, quantity: null, total: null };

const OrderBook = ({ orderBook = { bids: [], asks: [] } }) => {
  if (orderBook == null || !orderBook || !Array.isArray(orderBook.bids) || !Array.isArray(orderBook.asks)) {
    return <Typography color="textSecondary">No order book data available.</Typography>;
  }

  const processOrders = (orders, isBid = false) => {
    // Sort orders: descending for bids, ascending for asks
    const sortedOrders = [...orders].sort((a, b) => isBid ? b.price - a.price : a.price - b.price);
  
    const ordersWithTotal = sortedOrders.reduce((acc, order) => {
      const lastTotal = acc.length > 0 ? acc[acc.length - 1].total : 0;
      return [...acc, { ...order, total: lastTotal + order.quantity }];
    }, []);
  
    while (ordersWithTotal.length < MAX_ROWS) {
      ordersWithTotal.push(PLACEHOLDER_ROW);
    }
  
    return ordersWithTotal.slice(0, MAX_ROWS);
  };
  
  const bidsWithTotal = processOrders(orderBook.bids, true);  // Pass `true` for descending order
  const asksWithTotal = processOrders(orderBook.asks, false); // Default (ascending) for asks
  
  const maxTotal = Math.max(
    ...bidsWithTotal.map(b => b.total || 0),
    ...asksWithTotal.map(a => a.total || 0)
  );

  return (
    <Paper elevation={3} sx={{ padding: '20px', maxWidth: 1200, margin: 'auto', backgroundColor: '#303030' }}>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ color: '#ffffff' }}>
          Orderbook
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {/* Bids Section with Label */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" sx={{ color: 'success.main', textAlign: 'center', mb: 1 }}>
            Buy Orders
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#ffffff' }}>Price</TableCell>
                  <TableCell align="right" sx={{ color: '#ffffff' }}>Quantity</TableCell>
                  <TableCell align="right" sx={{ color: '#ffffff' }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bidsWithTotal.map((bid, index) => (
                  <TableRow key={index} sx={{ position: 'relative' }}>
                    <TableCell sx={{ color: bid.price ? 'success.main' : '#888888', position: 'relative', zIndex: 1 }}>
                      ${bid.price ? (bid.price / 100).toFixed(2) : "-.--"}
                    </TableCell>
                    <TableCell align="right" sx={{ position: 'relative', zIndex: 1, color: '#ffffff' }}>
                      {bid.quantity ? bid.quantity.toFixed(0) : "-"}
                    </TableCell>
                    <TableCell align="right" sx={{ position: 'relative', zIndex: 1, color: '#ffffff' }}>
                      {bid.total ? bid.total.toFixed(0) : "-"}
                    </TableCell>
                    {bid.price && (
                      <DepthBar type="bid" sx={{ width: `${(bid.total / maxTotal) * 100}%` }} />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Asks Section with Label */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" sx={{ color: 'error.main', textAlign: 'center', mb: 1 }}>
            Sell Orders
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#ffffff' }}>Price</TableCell>
                  <TableCell align="right" sx={{ color: '#ffffff' }}>Quantity</TableCell>
                  <TableCell align="right" sx={{ color: '#ffffff' }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asksWithTotal.map((ask, index) => (
                  <TableRow key={index} sx={{ position: 'relative' }}>
                    <TableCell sx={{ color: ask.price ? 'error.main' : '#888888', position: 'relative', zIndex: 1 }}>
                      ${ask.price ? (ask.price / 100).toFixed(2) : "-.--"}
                    </TableCell>
                    <TableCell align="right" sx={{ position: 'relative', zIndex: 1, color: '#ffffff' }}>
                      {ask.quantity ? ask.quantity.toFixed(0) : "-"}
                    </TableCell>
                    <TableCell align="right" sx={{ position: 'relative', zIndex: 1, color: '#ffffff' }}>
                      {ask.total ? ask.total.toFixed(0) : "-"}
                    </TableCell>
                    {ask.price && (
                      <DepthBar type="ask" sx={{ width: `${(ask.total / maxTotal) * 100}%` }} />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrderBook;
