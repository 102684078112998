import React from 'react';
import { FaFlagUsa } from 'react-icons/fa'; // Import American flag icon
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <h1>
        Welcome to Case-Exchange!
      </h1>
      <h4>
        The cheapest, fastest, and safest marketplace for CS2 items.
      </h4>
      <h4>
        We are a proud American company dedicated to providing the best service to our customers. <FaFlagUsa style={{ marginRight: '10px' }} />
      </h4>
    </div>
  );
};

export default Banner;
