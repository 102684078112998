import React, { useState } from 'react';
import {
  Box,
  Typography,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  Slider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Paper,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import './FilterMenu.css'; // Import the new CSS file

const FilterMenu = ({ filterParams, setFilterParams }) => {
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedSortOption, setSelectedSortOption] = useState('last_price');

  const filterOptions = {
    exterior: ["Factory New", "Minimal Wear", "Field-Tested", "Well-Worn", "Battle-Scarred"],
    category: ["Knife", "Gloves", "Rifle", "SMG", "Heavy", "Agent", "Charm", "Sticker", "Container", "Key", "Patch", "Graffiti", "Pin", "Pass", "Music Kit"].sort(),
    type: ["Case", "Capsule"],
    extras: ["StatTrak™", "Souvenir", "Sticker", "Charm"],
    sorting: ["quantity_listed", "last_price", "mhash", "exterior", "type", "category", "last_traded", "rarity"],
  };

  const [isFilterOpen, setIsFilterOpen] = useState({
    sorting: true,
    price: false,
    exterior: false,
    category: true,
    type: true,
    extras: false,
  });

  const toggleFilter = (filter) => {
    setIsFilterOpen(prev => ({ ...prev, [filter]: !prev[filter] }));
  };

  const handleFilterChange = (filterType, value, isChecked) => {
    console.log("handle filter change", filterType, value, isChecked);
    setFilterParams(prev => ({
      ...prev,
      [filterType]: isChecked
        ? [...(prev[filterType] || []), value]
        : (prev[filterType] || []).filter(option => option !== value),
    }));
  };

  const handleSortingChange = (e) => {
    const value = e.target.value;
    setSelectedSortOption(value);
    setFilterParams(prev => ({
      ...prev,
      sortingField: value,
      sortDirection,
    }));
  };

  const FilterSection = ({ title, isOpen, onToggle, children }) => (
    <Box
    >
      <Paper className="dark-paper" elevation={1}>
        <Box onClick={onToggle} className="flex justify-between items-center p-3 cursor-pointer">
          <Typography variant="subtitle2">{title}</Typography>
          <IconButton className="dark-icon-button" size="small">
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={isOpen}>
          <Box p={2}>{children}</Box>
        </Collapse>
      </Paper>
    </Box>
  );

  const handleSliderChange = (_, newValue) => {
    setPriceRange(newValue);
  };
  
  const handleSliderChangeCommitted = (_, newValue) => {
    console.log("handle slider change committed", newValue);

    setFilterParams((prev) => ({
      ...prev,
      priceRange: newValue,
    }));
  };

  return (
    <Box sx={{ backgroundColor: '#303030', color: '#ffffff', padding: '20px', minWidth: '125px'}}>
      <Typography variant="h6" align="center">Filters</Typography>
      <FilterSection title="Sorting" isOpen={isFilterOpen.sorting} onToggle={() => toggleFilter('sorting')}>
        <Box className="flex items-center gap-2">
          <FormControl fullWidth size="small">
            <Select
              value={selectedSortOption}
              onChange={handleSortingChange}
              displayEmpty
              className="dark-select"
            >
              <MenuItem className="dark-menu-item" value="" disabled>
                Select option
              </MenuItem>
              {filterOptions.sorting.map(option => (
                <MenuItem className="dark-menu-item" key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1).replace('_', ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" className="dark-select">
            <Select
              value={sortDirection}
              onChange={(e) => {
                const newDirection = e.target.value;
                setSortDirection(newDirection);
                setFilterParams(prev => ({
                  ...prev,
                  sortingOrder: newDirection,
                }));

              }}
              displayEmpty
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </FilterSection>

      <FilterSection title="Price" isOpen={isFilterOpen.price} onToggle={() => toggleFilter('price')}>
      <Slider
        className="dark-slider"
        value={priceRange}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
        valueLabelDisplay="auto"
        min={0}
        max={1000}
      />  
        <Typography align="center">${priceRange[0]} - ${priceRange[1]}</Typography>
      </FilterSection>

      {Object.entries(filterOptions).filter(([key]) => key !== 'sorting').map(([key, options]) => (
        <FilterSection key={key} title={key.charAt(0).toUpperCase() + key.slice(1)} isOpen={isFilterOpen[key]} onToggle={() => toggleFilter(key)}>
          <FormGroup>
            {options.map(option => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    className="dark-checkbox"
                    size="small"
                    onChange={(e) => handleFilterChange(key, option, e.target.checked)}
                    checked={filterParams?.[key]?.includes(option) || false}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
        </FilterSection>
      ))}
    </Box>
  );
};

export default FilterMenu;
