import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";

// Stripe setup
const stripePromise = loadStripe("pk_test_51QhIlaFSX19rVOuyqQ0iq7V2fs1PWSY96ddAXNOv576jVROhDtxN2FFnJlNwnJrMi5kYetboGnbAOFc5kFMy9Kme001MZU1eDC")
const CheckoutForm = () => {
  const fetchClientSecret = useCallback(() => {
    const price = 1000; // Example price in cents
    return fetch(`http://localhost:3005/create-checkout-session`, {
      method: "POST",
      headers: {
      "Content-Type": "application/json",
      },
      body: JSON.stringify({ price }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
      Stripe Secure Checkout 🔒
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
