import React, { useState } from 'react';
import { supabase } from '../supabaseClient'; // Assuming supabaseClient.js is set up
import { Container, Typography, Button, Box, Paper, CircularProgress } from '@mui/material'; // MUI components
import GoogleIcon from '@mui/icons-material/Google'; // MUI icon for Google

const SignInPage = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOAuthSignIn = async (provider) => {
    setLoading(true);
    setErrorMessage('');

    const { error } = await supabase.auth.signInWithOAuth({ provider });

    setLoading(false); // Stop loading regardless of success or failure

    if (error) {
      setErrorMessage(`Error signing in with ${provider}: ${error.message}`);
      console.error('Error signing in with provider:', error.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#454545', // Subtle dark gray background
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={5} sx={{ p: 4, backgroundColor: '#ffffff' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Sign Into Case Exchange
          </Typography>
          
          {errorMessage && (
            <Typography color="error" align="center" gutterBottom>
              {errorMessage}
            </Typography>
          )}
          
          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="contained"
              color="primary"
              startIcon={loading ? <CircularProgress size={24} /> : <GoogleIcon />}
              onClick={() => handleOAuthSignIn('google')}
              disabled={loading}
              sx={{ width: '100%', py: 1.5 }}
            >
              {loading ? 'Signing in...' : 'Sign in with Google'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignInPage;
