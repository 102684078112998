import React, { useEffect, useState, useRef } from 'react';
import './ItemCard.css';

const ItemCard = ({ item }) => {
  const [highlight, setHighlight] = useState(false);
  const [lastTradedTimeDisplay, setLastTradedTimeDisplay] = useState('');
  const previousPrice = useRef(null);
  const lastTraded = useRef(null);

  useEffect(() => {
    if (
      item.last_price !== undefined &&
      previousPrice.current !== null &&
      (item.last_price !== previousPrice.current || item.last_traded !== lastTraded.current)
    ) {
      setHighlight(true);
      const timeout = setTimeout(() => setHighlight(false), 1000);
      return () => clearTimeout(timeout);
    }

    previousPrice.current = item.last_price;
    lastTraded.current = item.last_traded;
  }, [item.last_price, item.last_traded]);

  // Format time since last trade or use January 31, 2025 as fallback
  const formatLastTradedTime = (timestamp) => {
    const baseDate = new Date('2025-01-31T00:00:00Z');
    const referenceDate = timestamp ? new Date(timestamp) : baseDate;
    const diffMs = Date.now() - referenceDate.getTime();
    const seconds = Math.floor(diffMs / 1000);

    if (seconds < 0) return `0s`;
    if (seconds < 60) return `${seconds}s`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)}m`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)}H`;
    return `${Math.floor(seconds / 86400)}D`;
  };

  // Update displayed last traded time every minute
  useEffect(() => {
    const updateDisplay = () => setLastTradedTimeDisplay(formatLastTradedTime(item.last_traded));

    updateDisplay(); // Initial display update
    const interval = setInterval(updateDisplay, 10000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [item.last_traded]);

  return (
    <a href={`/item/${item.mhash}`} className="item-card-link" rel="noopener noreferrer">
      <div className={`item-card ${highlight ? 'highlight' : ''}`}>
      <div class="cropped-image">
        <img
          id="result_8_image"
          className="item-icon"
          src={`https://community.fastly.steamstatic.com/economy/image/${item.image_link}/`}
          alt=""
        />
      </div>
        <h3 className="item-name">{item.mhash}</h3>

        {item.last_price > 0 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className="item-price" style={{ margin: '2px' }}>
              ${(item.last_price / 100).toFixed(2)} USD
            </p>
          </div>
        ) : (
          <p className="item-price">$-.-- USD</p>
        )}

        <p className="item-last-traded">
          Traded {lastTradedTimeDisplay} ago
        </p>
      </div>
    </a>
  );
};

export default ItemCard;
