import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import './OrdersTable.css'; // Assuming this CSS file is created

const OrdersTable = ({ orders = [], onCancelOrder }) => {
  const [sortField, setSortField] = useState('order_placed');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleDelete = async (orderId) => {
    try {
      await onCancelOrder(orderId);
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const calculateTotalValue = (price, quantity, filledUnits) => {
    return (price / 100) * (quantity - filledUnits);
  };

  const handleSort = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  return (
    <div>
      <div className="orders-table-container">
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: '#ffffff' }}>
            Outstanding Orders
          </Typography>
        </Box>
        {orders && orders.length > 0 ? (
          <TableContainer component={Paper} elevation={8}>
            <Table>
            <TableHead className="orders-table-header">
              <TableRow>
                <TableCell className="orders-table-header-cell orders-table-row-header-cell">
                  Price
                </TableCell>
                <TableCell className="orders-table-header-cell orders-table-row-header-cell">
                  Units <span style={{ color: 'rgba(183, 183, 183, 0.5)' }}>(Filled / Total)</span>
                </TableCell>

                <TableCell className="orders-table-header-cell orders-table-row-header-cell">
                  Total Order Value
                </TableCell>
                <TableCell className="orders-table-header-cell orders-table-row-header-cell">
                  Order Type
                </TableCell>
                <TableCell className="orders-table-header-cell orders-table-row-header-cell">
                  Order Placed
                </TableCell>
                <TableCell align="center" className="orders-table-header-cell orders-table-row-header-cell">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

              <TableBody>
                {orders.map((order, index) => (
                  <TableRow key={index} className="orders-table-row">
                    <TableCell className="orders-table-cell">
                      ${(order.price / 100).toFixed(2)}
                    </TableCell>
                    <TableCell className="orders-table-cell">
                      {`${order.filled_units} / ${order.quantity}`}
                    </TableCell>
                    <TableCell className="orders-table-cell">
                      ${calculateTotalValue(order.price, order.quantity, order.filled_units).toFixed(2)}
                    </TableCell>
                    <TableCell className={`orders-table-cell ${order.is_buy_order ? 'order-buy' : 'order-sell'}`}>
                      {order.is_buy_order ? 'Buy ' : 'Sell '} 
                      <span style={{ color: 'rgba(183, 183, 183, 0.5)' }}>
                         ({order.is_limit_order ? 'Limit' : 'Market'})
                      </span>
                      
                    </TableCell>
                    <TableCell className="orders-table-cell">
                      {new Date(order.order_placed).toLocaleString()}
                    </TableCell>
                    <TableCell align="center" className="orders-table-cell">
                      <IconButton className="delete-icon" onClick={() => handleDelete(order.order_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p style={{ color: '#ffffff' }}>{orders === null ? 'Loading orders...' : 'No outstanding orders available.'}</p>
        )}
      </div>
    </div>
  );
};

export default OrdersTable;
