import React from 'react';
import OrdersTable from './OrdersTable';

const ItemOrders = ({ orders, onCancelOrder }) => (
  <div>
    <OrdersTable orders={orders} onCancelOrder={onCancelOrder} />
  </div>
);

export default ItemOrders;
