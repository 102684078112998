import React from 'react';
import MarketOrderForm from './MarketOrderForm';

const ItemMarketForm = ({ mhash }) => (
  <div className="middle-column">
    <MarketOrderForm mhash={mhash} />
  </div>
);

export default ItemMarketForm;
