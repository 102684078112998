import React from 'react';

const ItemImage = ({ imageLink }) => (
  <div className="cropped-image">
    <img
      id="result_8_image"
      className="item-icon"
      src={`https://community.fastly.steamstatic.com/economy/image/${imageLink}/`}
      alt="Item"
    />
  </div>
);

export default ItemImage;
