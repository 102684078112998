import React from 'react';
import './FAQSection.css'; // Add styles as needed

const FAQSection = () => {
  return (
    <>
      <div className="faq-section" style={{ backgroundColor: "#545454", height: '100vh', padding: '20px', maxWidth: '1200px' }}>
        <h2 style={{ color: '#ffffff' }}>Frequently Asked Questions</h2>
        <ul style={{ color: '#ffffff' }}>
          <li><strong>How can I get started?</strong></li>
          <p>Start by creating an account here SIGN UP LINK, and depositing either items or balance to start trading.</p>
          
          <li><strong>How to buy skins?</strong></li>
          <p> To buy skins simply navigate to the market page and select an item you would like to purchase. Finally place an order and the item is yours.</p>
          
          <li><strong>How to sell skins?</strong></li>
          <p>To sell skins navigate the to the item page by clicking on the item in your inventory. Finally list the item for sale with a sell listing. </p>
          
          <li><strong>How to deposit skins?</strong></li>
          <p> Unfortunately we currently do not offer deposits of skins.</p>
          
          <li><strong>How to withdraw skins?</strong></li>
          <p>To withdraw your items, go to the inventory page and select the items and quantity you would like to withdraw. A trade will be sent to your Steam account with your items. </p>

          <li><strong>Are my skins safe?</strong></li>
          <p>Security is our utmost priority. Case-Exchange uses Open Authorization so you don't need a password, your account is linked to your email.</p>

          <li><strong>Do I have to be a US customer?</strong></li>
          <p>Yes you must be in the US to deposit balance onto the site, this is so we can provide the best experience for our users.</p>
          
          <li><strong>What payment methods are accepted?</strong></li>
          <p>We accept a variety of payment methods, including all major US debit cards. We plan to accept more payment methods in the future.</p>
          
          <li><strong>Can I withdraw funds?</strong></li>
          <p>Unfortunately as of now we do not offer balance withdrawals. We are currently working on adding withdrawal capabilities. Stay tuned!</p>
        </ul>
      </div>
      <div className="faq-section" style={{ backgroundColor: "#545454", height: '100vh', padding: '20px', maxWidth: '1200px' }}>
      <h2 style={{ color: '#ffffff' }}>About Us</h2>
      <ul style={{ color: '#ffffff' }}>
        Case Exchange is made by a small team of developers who are passionate about gaming and trading. We aim to provide a safe and secure platform for users to trade their items. We are constantly working on improving the site and adding new features. If you have any suggestions or feedback, feel free to reach out to us at info@case-exhcange.com 

        We are looking to build the best CS2 trading platform through our relentless focus on customer service and user experience. We are always looking for ways to improve and would love to hear from you.
      </ul>
    </div>
  </>
  );
};

export default FAQSection;
