import React, { useEffect, useRef, useState } from 'react';
import { createChart, AreaSeries, CandlestickSeries } from 'lightweight-charts';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';

const ItemChart = () => {
  const chartContainerRef = useRef();
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [chartType, setChartType] = useState('area');
  const [currentSeries, setCurrentSeries] = useState(null); // Track the current series

  // Update chart dimensions on resize
  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
        setChartHeight(chartContainerRef.current.offsetHeight);
      }
    };

    // Set initial size
    handleResize();

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Also trigger resize manually on mount to get the initial dimensions
    requestAnimationFrame(handleResize); // Ensure it gets the correct initial size

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Create or update chart
  useEffect(() => {
    if (chartWidth === 0 || chartHeight === 0) return; // Wait until dimensions are set

    // Chart options
    const chartOptions = {
      layout: {
        textColor: 'black',
        background: {
          type: 'solid',
          color: '#303030',
        },
        textColor: 'rgba(183, 183, 183, 0.5)',
      },
      grid: {
        vertLines: {
          color: 'rgba(183, 183, 183, 0.5)', // Lighter gray with half opacity for vertical grid lines
        },
        horzLines: {
          color: 'rgba(183, 183, 183, 0.5)', // Lighter gray with half opacity for horizontal grid lines
        },
      },
    };

    // Create the chart
    const chart = createChart(chartContainerRef.current, {
      ...chartOptions,
      width: chartWidth,
      height: chartHeight,
    });

    // Sample data
    const areaData = [
      { value: 0, time: 1642425322 },
      { value: 8, time: 1642511722 },
      { value: 10, time: 1642598122 },
      { value: 20, time: 1642684522 },
      { value: 3, time: 1642770922 },
      { value: 43, time: 1642857322 },
      { value: 41, time: 1642943722 },
      { value: 43, time: 1643030122 },
      { value: 56, time: 1643116522 },
      { value: 46, time: 1643202922 },
    ];

    const candlestickData = [
      { open: 10, high: 10.63, low: 9.49, close: 9.55, time: 1642427876 },
      { open: 9.55, high: 10.30, low: 9.42, close: 9.94, time: 1642514276 },
      { open: 9.94, high: 10.17, low: 9.92, close: 9.78, time: 1642600676 },
      { open: 9.78, high: 10.59, low: 9.18, close: 9.51, time: 1642687076 },
      { open: 9.51, high: 10.46, low: 9.10, close: 10.17, time: 1642773476 },
      { open: 10.17, high: 10.96, low: 10.16, close: 10.47, time: 1642859876 },
      { open: 10.47, high: 11.39, low: 10.40, close: 10.81, time: 1642946276 },
      { open: 10.81, high: 11.60, low: 10.30, close: 10.75, time: 1643032676 },
      { open: 10.75, high: 11.60, low: 10.49, close: 10.93, time: 1643119076 },
      { open: 10.93, high: 11.53, low: 10.76, close: 10.96, time: 1643205476 },
    ];



    // Create the appropriate series based on the chartType state
    let newSeries = null;
    if (chartType === 'area') {
      newSeries = chart.addSeries(AreaSeries, {
        lineColor: '#ffffff',
        topColor: 'rgba(204, 164, 54, 0.5)',
        bottomColor: 'rgba(0, 0, 0, 0)',
        background: 'rgba(204, 164, 54, 0.5)',
      });
      newSeries.setData(areaData);
    } else if (chartType === 'candlestick') {
      newSeries = chart.addSeries(CandlestickSeries, {
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderVisible: false,
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
        width: '100%'
      });
      newSeries.setData(candlestickData);
    }

    // Set the new series to state if it's different
    if (newSeries !== currentSeries) {
      setCurrentSeries(newSeries);
    }

    // Fit content to the time scale
    chart.timeScale().fitContent();

    // Cleanup chart on component unmount
    return () => {
      chart.remove();
    };
  }, [chartWidth, chartHeight, chartType]); // Redraw the chart when the container size or chart type changes

  // Toggle chart type
  const toggleChartType = () => {
    setChartType((prevType) => (prevType === 'area' ? 'candlestick' : 'area'));
  };

  return (
    <div style={{ width: '100%', height: '100%' }}> {/* Full viewport height */}
      <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} /> 
      <ToggleButtonGroup
        value={chartType}
        exclusive
        onChange={toggleChartType}
        aria-label="chart type"
        style={{ marginBottom: '10px' }}
      >
        <ToggleButton value="area" aria-label="Area chart" style={{ height: '25px', width: '70px' }}>
          Line
        </ToggleButton>
        <ToggleButton value="candlestick" aria-label="Candlestick chart" style={{ height: '25px', width: '100px' }}>
          Candles
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default ItemChart;
