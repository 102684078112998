import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  FirstPage,
  LastPage,
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material';

const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  onPageChange,
  itemsPerPage = 10,
  totalItems = 0,
  className = '',
}) => {
  const [inputPage, setInputPage] = useState(currentPage.toString());
  const [error, setError] = useState(false);

  useEffect(() => {
    setInputPage(currentPage.toString());
  }, [currentPage]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputPage(value);
    setError(false);
  };

  const handleInputSubmit = (event) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(inputPage, 10);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        onPageChange(pageNumber);
      } else {
        setError(true);
      }
    }
  };

  const handleBlur = () => {
    if (isNaN(parseInt(inputPage, 10)) || parseInt(inputPage, 10) < 1 || parseInt(inputPage, 10) > totalPages) {
      setInputPage(currentPage.toString());
      setError(false);
    }
  };

  return (
    <Box className={className} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, p: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="First Page">
          <span>
            <IconButton onClick={() => onPageChange(1)} disabled={currentPage === 1} size="small">
              <FirstPage />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Previous Page">
          <span>
            <IconButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} size="small">
              <NavigateBefore />
            </IconButton>
          </span>
        </Tooltip>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            size="small"
            value={inputPage}
            onChange={handleInputChange}
            onKeyPress={handleInputSubmit}
            onBlur={handleBlur}
            error={error}
            sx={{ width: '70px' }}
            inputProps={{ style: { textAlign: 'center' }, 'aria-label': 'Page number' }}
          />
          <Typography variant="body2">of {totalPages}</Typography>
        </Box>

        <Tooltip title="Next Page">
          <span>
            <IconButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} size="small">
              <NavigateNext />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Last Page">
          <span>
            <IconButton onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} size="small">
              <LastPage />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <Typography variant="body2" color="text.secondary">
        {totalItems > 0
          ? `Showing ${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalItems)} of ${totalItems} items`
          : 'No items to display'}
      </Typography>
    </Box>
  );
};

export default Pagination;
