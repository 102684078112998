import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import Homebar from './Homebar';
import ItemHeader from './ItemHeader';
import ItemImage from './ItemImage';
import ItemOrders from './ItemOrders';
import ItemMarketForm from './ItemMarketForm';
import ItemOrderBook from './ItemOrderBook';
import ItemChart from './ItemChart';

import './ItemDetailPage.css';
import { AlignCenter } from 'lucide-react';
import Footer from './Footer';

const ItemDetailPage = () => {
  const { mhash: encodedMhash } = useParams();
  const mhash = decodeURIComponent(encodedMhash.replace(/\+/g, ' '));

  const [item, setItem] = useState(null);
  const [orders, setOrders] = useState(null);
  const [orderbook, setOrderbook] = useState(null);

  const handleCancelOrder = async (orderId) => {
    console.log("Trying to cancel order #", orderId);
    let { data, error } = await supabase.rpc('cancel_commodity_order', {
      p_order_id: orderId 
    });
    if (error) {
      console.log("Error trying to cancel order", error);
    } else {
      console.log("Successful cancel order");
    }
  };

  const fetchItemDetails = async () => {
    try {
      const { data: itemData, error: itemError } = await supabase
        .from('Item')
        .select('*')
        .eq('mhash', mhash);

      if (itemError) {
        throw new Error(`Error fetching item: ${itemError.message}`);
      }

      const fetchedItem = itemData[0];
      setItem(fetchedItem);

      const { data: orderData, error: orderError } = await supabase
      .from(fetchedItem.is_commodity ? 'CommodityOrder' : 'UniqueOrder')
      .select('order_id, price, quantity, filled_units, is_buy_order, is_limit_order, order_placed')
      .eq('mhash', mhash)
      .eq('is_outstanding', true);

      if (orderError) {
        throw new Error(`Error fetching outstanding orders: ${orderError.message}`);
      }

      setOrders(orderData);

      if (fetchedItem && fetchedItem.is_commodity) {
        const { data: orderBook, error: orderBookError } = await supabase
          .from('CommodityOrderBook')
          .select('*')
          .eq('mhash', mhash);
        if (orderBookError) {
          throw new Error(`Error fetching order book: ${orderBookError.message}`);
        }
        if (orderBook.orderbook_data) {
          setOrderbook(orderBook[0].orderbook_data);
        } else {
          setOrderbook({ bids: [], asks: [] });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchItemDetails();
  }, [mhash]);

  if (!item) {
    return <div style={{ backgroundColor: "#303030", height:'100vh', color: '#ffffff'}}>Item not found...</div>;
  }

  return (
    <div style={{ backgroundColor: "#303030", height:'100%', color: '#ffffff', overflowY: 'scroll', minHeight: '100vh'}} >
      <Homebar />
      {item.is_commodity ? (
        <div style={{placeItems: 'center', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#303030', color: '#ffffff'}}>  
          <div className="page-container">
            <div className="item-header">
              <ItemImage imageLink={item.image_link} />
              <ItemHeader item={item} />
            </div>
            <div>
            <div className="mid-row">
              {/* 70% section for the ItemChart */}
              <div className="item-chart">
                <ItemChart item={item} /> {/* Item chart component */}
              </div>

              {/* 30% section for the ItemMarketForm */}
              <div className="item-market-form">
                <ItemMarketForm mhash={mhash} /> {/* Item order form component */}
              </div>
            </div>
              <div className="item-order-book">
                <ItemOrderBook orderBook={orderbook} />
              </div>
            </div>
            <ItemOrders orders={orders} onCancelOrder={handleCancelOrder} />
          </div>
        </div>
      ) : (
        <>
          <h1>Unique Item: {item.mhash}</h1>
          <p>Category: {item.category}</p>
        </>
      )}
      <Footer />
    </div>
  );
  
};

export default ItemDetailPage;
