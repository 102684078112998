import React, { useState, useEffect } from 'react';
// Muiization
import { TextField, Button, ToggleButton, ToggleButtonGroup, Alert, Box, Typography } from '@mui/material';
import { supabase } from '../supabaseClient';
import { useUserContext } from '../context/UserContext'; // Import the UserContext

const MarketOrderForm = ({mhash}) => {
  const { session } = useUserContext(); // Get user from context
  // const [orderType, setOrderType] = useState('market');
  const [orderSide, setOrderSide] = useState('buy');
  const [quantity, setQuantity] = useState('0');
  const [price, setPrice] = useState('0.00');
  const [error, setError] = useState(''); // Error state
  const [success, setSuccess] = useState(''); // Success state

  const [isDisabled, setIsDisabled] = useState(!session || !session.user);

  useEffect(() => {
    setIsDisabled(!session || !session.user);
  }, [session]);


  // If session or user is not available, show a login prompt
  // if (!session || !session.user) {
  //   return <div>Please log in to place orders.</div>;
  // }

  // const handleOrderTypeChange = (event, newOrderType) => {
  //   if (newOrderType !== null) {
  //     setOrderType(newOrderType);
  //   }
  // };

  const handleOrderSideChange = (event, newOrderSide) => {
    if (newOrderSide !== null) {
      setOrderSide(newOrderSide);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!quantity || !price) {
      setError('Please fill in all fields');
      setSuccess('');
      return;
    }
    if (parseInt(quantity) <= 0) {
      setError('Quantity must be greater than zero');
      setSuccess('');
      return;
    }
    if (parseFloat(price) <= 0) {
      setError('Price must be greater than zero');
      setSuccess('');
      return;
    }

    setError('');
    setSuccess('');

    // Order data to insert
    const orderData = {
      p_mhash: mhash,
      p_is_buy_order: orderSide === 'buy',
      p_is_limit_order: true, //orderType === 'limit',
      p_price: (price * 100).toFixed(0),
      p_quantity: parseInt(quantity).toFixed(0),
    };

    console.log("Creating order with orderdata: ", orderData)

    // RPC call to create the order
    let { data, error } = await supabase.rpc('create_order', {
      p_mhash: orderData.p_mhash, 
      p_is_buy_order: orderData.p_is_buy_order, 
      p_is_limit_order: orderData.p_is_limit_order, 
      p_price: orderData.p_price, 
      p_quantity: orderData.p_quantity, 
    });

    // Handle API response
    if (error) {
      setError(`Error: ${error.message}`); // Set error message
      setSuccess(''); // Clear success message if there's an error
    } else {
      setSuccess('Order successfully submitted!'); // Set success message
      setError(''); // Clear error message if successful
    }

    // Reset fields after submission
    // setQuantity('0');
    // setPrice('0.00');
  };


  return (
    <Box sx={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto' }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
  
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: '#ffffff' }}>
            {orderSide === 'buy' ? "Buy" : "Sell"} {mhash}
          </Typography>
        </Box>
  
        <Box>
          <ToggleButtonGroup
            value={orderSide}
            exclusive
            onChange={handleOrderSideChange}
            aria-label="order side"
            fullWidth
            size="small"
            disabled={isDisabled} // Disables the toggle buttons
          >
            <ToggleButton value="buy" sx={{ flex: 1 }}>
              Buy
            </ToggleButton>
            <ToggleButton value="sell" sx={{ flex: 1 }}>
              Sell
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
  
        <TextField
          fullWidth
          label="Quantity"
          variant="outlined"
          type="number"
          value={quantity}
          onChange={(e) => {
            const value = e.target.value;
            if (/^(0|[1-9]\d*)?$/.test(value)) {
              setQuantity(value);
            }
          }}
          margin="normal"
          placeholder="Enter quantity"
          disabled={isDisabled} // Disables input field
        />
  
        <TextField
          fullWidth
          label="Price Per Unit USD $"
          variant="outlined"
          type="number"
          value={price}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d{0,2}$/.test(value)) {
              setPrice(value);
            }
          }}
          margin="normal"
          placeholder="Enter price"
          disabled={isDisabled} // Disables input field
        />
  
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 0.5 }}
          disabled={isDisabled} // Disables the submit button
        >
          Submit Order
        </Button>
      </form>
  
      <Box sx={{ mt: 0.5, p: 1, border: '3px solid #ccc', borderRadius: '5px', backgroundColor: '#303030', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          <strong>Order Summary</strong>
        </Typography>
        <Typography variant="body1">
          {'Limit'} {orderSide === 'buy' ? 'Buy' : 'Sell'} {quantity} {mhash} @ ${price}
          <br />
          <strong>Subtotal:</strong> $ {(quantity * price).toFixed(2)}
          <br />
          <strong>Total:</strong> $ {(quantity * price).toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default MarketOrderForm;
