import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Switch,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  FormControlLabel
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import Homebar from './Homebar';
import Footer from './Footer';
import './SettingsPage.css';  // Import the new CSS file

const SettingsPage = () => {
  const [apiKey, setApiKey] = useState('sk-xxxx-xxxx-xxxx-xxxx');
  const [showApiKey, setShowApiKey] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState({
    WebsiteUpdates: false,
    MarketUpdates: true,
    Logins: true
  });
  
  const [accounts, setAccounts] = useState([
    { id: 1, name: 'TraderJoe', steamId: '76561198011111111', balance: '$1,234.56', status: 'Active' },
    { id: 2, name: 'CS2Pro', steamId: '76561198022222222', balance: '$567.89', status: 'Inactive' }
  ]);

  // Menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleMenuOpen = (event, account) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccount(account);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAccount(null);
  };

  return (
    <div className="settings-page">
      <Homebar />
      <div className="content-container">
      <Container>
      <h1 style={{ color: '#ffffff' }}>
              Settings
      </h1>

        {/* Email Notifications */}
        <Card className="card">
          <CardContent>
            <h2 style={{ color: '#ffffff' }}>
              Notifications
            </h2>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {Object.entries(emailNotifications).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) =>
                        setEmailNotifications((prev) => ({
                          ...prev,
                          [key]: e.target.checked
                        }))
                      }
                    />
                  }
                  label={key.replace(/([A-Z])/g, ' $1').trim()}
                />
              ))}
            </Box>
          </CardContent>
        </Card>

        {/* Steam Accounts */}
        <Card className="card">
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <h2 style={{ color: '#ffffff' }}>
                Linked Steam Accounts
              </h2>
              <Button startIcon={<AddIcon />} variant="contained">
                Add Account
              </Button>
            </Box>
            
            <TableContainer component={Paper} variant="outlined" className="dark-paper">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#ffffff' }}>Account</TableCell>
                    <TableCell style={{ color: '#ffffff' }}>Steam ID</TableCell>
                    <TableCell style={{ color: '#ffffff' }}>Status</TableCell>
                    <TableCell align="right" style={{ color: '#ffffff' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts.map((account) => (
                    <TableRow key={account.id}>
                      <TableCell style={{ color: '#ffffff' }}>{account.name}</TableCell>
                      <TableCell style={{ color: '#ffffff' }}>{account.steamId}</TableCell>
                      <TableCell style={{ color: '#ffffff' }}>
                        <Chip
                          label={account.status}
                          color={account.status === 'Active' ? 'success' : 'default'}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right" style={{ color: '#ffffff' }}>
                        <IconButton
                          size="small"
                          onClick={(e) => handleMenuOpen(e, account)}
                          className="dark-icon-button"
                        >
                          <SettingsIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>Edit Account</MenuItem>
              <MenuItem onClick={handleMenuClose} sx={{ color: 'error.main' }}>
                Remove Account
              </MenuItem>
            </Menu>
          </CardContent>
        </Card>

        {/* API Key Section */}
        <Card className="card">
          <CardContent>
            <h2 style={{ color: '#ffffff' }}>
              API Key
            </h2>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  flex: 1,
                  fontFamily: 'monospace',
                  bgcolor: 'grey.100',
                  p: 2,
                  borderRadius: 1
                }}
              >
                {showApiKey ? apiKey : '•'.repeat(apiKey.length)}
              </Box>
              <Button
                variant="outlined"
                startIcon={showApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
                onClick={() => setShowApiKey(!showApiKey)}
              >
                {showApiKey ? 'Hide' : 'Show'} API Key
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
      </div>
      <Footer />
    </div>
  );
};

export default SettingsPage;
