import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import FilterMenu from './FilterMenu';
import ItemsList from './ItemsList';
import './MarketPage.css';
import Homebar from './Homebar';
import Pagination from './Pagination';
import Footer from './Footer';

const MarketPage = () => {
  const [items, setItems] = useState([]);
  const [filterParams, setFilterParams] = useState({
    priceRange: [0, 1000],
    exterior: [],
    category: [],
    type: ["Case"],
    extra: [],
    sortingOrder: 'asc',
    sortingField: 'last_traded',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50); // @TODO MOVE THIS ELSEWHERE PROBABLY TO DB

  useEffect(() => {
    fetchItems();
  }, [filterParams, currentPage]);

  const fetchItems = async () => {
    try {
      let query = supabase
        .from('Item')
        .select('id, mhash, is_commodity, last_price, last_traded, image_link')
        .eq('is_commodity', true)
        .range((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage - 1);

      // Price Range Filter
      if (filterParams.priceRange && filterParams.priceRange.length === 2) {
        query = query.gte('last_price', filterParams.priceRange[0] * 100)
          .lte('last_price', filterParams.priceRange[1] * 100);
      }

      // Exterior Filter
      if (filterParams.exterior.length > 0) {
        query = query.in('exterior', filterParams.exterior);
      }

      // Category Filter
      if (filterParams.category.length > 0) {
        query = query.in('category', filterParams.category);
      }

      // Type Filter
      if (filterParams.type.length > 0) {
        console.log("TYPE FILTER", filterParams.type);
        query = query.in('type', filterParams.type);
      }

      // Extras Filter
      if (filterParams.extra.length > 0) {
        query = query.in('extras', filterParams.extra);
      }

      // If sorting field is specified, apply sorting
      if (filterParams.sortingField) {
        query = query.order(filterParams.sortingField, { ascending: filterParams.sortingOrder === 'asc' });
      }

      const { data, error } = await query;
      if (error) throw error;
      setItems(data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    const channel = supabase
      .channel('public:Item')
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'Item' }, (payload) => {
        console.log('Update received for mhash:', payload.new.mhash);
  
        // Filter updates based on currently selected filters
        setItems((prevItems) => {
          if (filterParams.priceRange &&
            (payload.new.last_price < filterParams.priceRange[0] * 100 || payload.new.last_price > filterParams.priceRange[1] * 100)
          ) {
            return prevItems;
          }
          return prevItems.map((item) =>
            item.mhash === payload.new.mhash ? { ...item, ...payload.new } : item
          );
        });
      })
      .subscribe();
  
    // Clean up the subscription only when the component unmounts
    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <div className="market-page">
      <Homebar />
      <div className="market-content-container">
        <div classname="market-filter-menu">
          <FilterMenu filterParams={filterParams} setFilterParams={setFilterParams} />
        </div>
        <div className="market-main-content">
          <ItemsList items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(1000 / itemsPerPage)}
            itemsPerPage={itemsPerPage}
            onPageChange={(pageNumber) => setCurrentPage(pageNumber)} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketPage;
